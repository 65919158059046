import { Component, ContentChild, HostBinding, Input, TemplateRef,} from '@angular/core';

/**
 * Component used to display data
 * @name Key-value
 * @tags Data, Display, Summary, Key, Value
 */
@Component({
    selector:  'gc-kv',
    styleUrls: ['./gc-kv.component.sass'],
    template:  `
    <span class="gc-kv" *ngIf="value || (!value && !hideOnEmpty)">
        <div class="key">
            <ng-container [ngTemplateOutlet]="headerTemplateRef" [ngTemplateOutletContext]="{$implicit: key}">
                <ng-container *ngIf="!headerTemplateRef">
                    {{key}}
                </ng-container>
            </ng-container>
        </div>
        <div class="value" #ref [ngClass]= "{'less-intensity': highlightEmpty && [null, undefined].includes(value) && !hasContent(ref)}">
            <ng-content></ng-content>
        </div>
        <ng-container *ngIf="!hasContent(ref)" [ngTemplateOutlet]="contentTemplateRef" [ngTemplateOutletContext]="{$implicit: value ?? nullValue}">
            <ng-container *ngIf="!contentTemplateRef">
                <div class="value" [ngClass]= "{'less-intensity': highlightEmpty && [null, undefined].includes(value) && !hasContent(ref)}">
                    {{value ?? nullValue}}
                </div>
            </ng-container>
        </ng-container>
    </span>`
})
export class GcKVComponent{
    @ContentChild('content', { static: false}) public contentTemplateRef: TemplateRef<unknown>;
    @ContentChild('header', { static: false}) public headerTemplateRef: TemplateRef<unknown>;
    /**
     * @default false
     */
    @Input() @HostBinding('class.container') public container: boolean = false;
    /**
     * If set to true, it will hide the component when "value" is empty.
     * @default false
     */
    @Input() public hideOnEmpty: boolean = false;
    /**
     * Used for adding the styling class "less-intensity".
     * @default true
     */
    @Input() public highlightEmpty: boolean = true;
    /**
     * It's the bolded text/header shown first. Key can be anything.
     */
    @Input() public key: unknown;
    /**
     * A text shown when the value is empty. It translates to "Not specified".
     * @default 'Keine Angabe'
     */
    @Input() public nullValue: string = 'Keine Angabe';
    /**
     * Value can be anything.
     * @example Simple KV
     * ```
     * %gc-kv{key: "My Key", value: "My Value"}
     * ```
     * @example Content Projection
     * ```
     * %gc-kv{key: "My Key"}
     *      My Value
     * ```
     * @example Templating
     * ``` 
     * %gc-kv{key: "My Key", value: "My Value"}
     *      %ng-template{#header, let-data}
     *          {{data}}
     *      %ng-template{#content, let-data}
     *          {{data}}
     * ```  
     */
    @Input() public value: unknown;

    public hasContent(ref: HTMLElement){
        return ref.children.length > 0 || ![null, undefined, ''].includes(ref.textContent?.trim());
    }
}
