export enum DebugEntryType {
    unknown,
    navigation,
    apiCall,
    clientError,
    catch,
    console,
    unauthorized,
}

export type DebugEntry =
    { type: DebugEntryType.unknown, source?: string, data?: unknown } |
    { type: DebugEntryType.navigation, event: unknown } |
    { type: DebugEntryType.apiCall, info: unknown, result: unknown, exception: unknown, time: number } |
    { type: DebugEntryType.clientError, error: unknown, report: unknown } |
    { type: DebugEntryType.catch, error: unknown } |
    { type: DebugEntryType.console, severity: 'log' | 'warn' | 'error', args: unknown[] } |
    { type: DebugEntryType.unauthorized, required: string };
