import { Injectable, signal } from '@angular/core';
import { CommClient, CommMessage_invoke, IRRPCCall } from '@vierkant-software/remoterpc';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventBody, RPC, RRPCTypes } from '@vierkant-software/types__api';
import { APIError } from 'src/util/errors';
import { AppService, InternalAppService } from './app.service';
import { DebugService } from './debug.service';

declare global {
  export interface Window {
      apiDebug(): boolean;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private con: Promise<void>;
  private socket: CommClient;

  public static instance: ApiService;
  #debug: boolean = false;

  #events: Subject<{ sessionId?: string, deviceId?: string, data: EventBody }> = new Subject();
  #status: Subject<'disconnected' | 'connected' | 'error'> = new BehaviorSubject('disconnected');

  get $events() {
    return this.#events.asObservable();
  }

  get $status() {
    return this.#status.asObservable();
  }

  versionIsValid = signal(true);

  constructor() {
    this.socket = new CommClient(undefined, async (client: CommClient, msg: CommMessage_invoke) => {
      switch(msg.command) {
        case 'CLIENT.callbackEvent':
          if (this.#debug) console.log('APInotify:', msg.args);
          this.#events.next(<{sessionId?: string, deviceId?: string, data: EventBody}>msg.args);
          return { result: undefined };
        default:
          // eslint-disable-next-line no-throw-literal
          throw { type: 'APIError', status: 'MISSING_FUNCTION', msg: 'Callback type not supported', severity: 'unknown'};
      };
    });
    this.con = this.socket.connect(environment.host);
    this.socket.on('on_reconnected', () => this.#status.next('connected'));
    this.socket.on('close', () => this.#status.next('disconnected'));
    this.socket.on('reconnect_failed', () => this.#status.next('error'));
    this.socket.connecting.then(() => this.#status.next('connected')).catch(() => null);

    ApiService.instance = this;
    window.apiDebug = (val: boolean = false) => {
      this.#debug = val;
      return val;
    };
  }

  public get $connecting() {
    return this.con;
  }

  getInterface(studio: string, session: string) {
    const rpc = this.socket.getInterface<RPC>(RRPCTypes, studio, session);
    rpc.onBefore = this.onBefore;
    rpc.onAfter = this.onAfter;
    return rpc;
  }

  async onBefore(this: RPC, info: IRRPCCall & { start_time?: number }): Promise<boolean> {
    info.start_time = Date.now();
    return true;
  }

  async onAfter(this: RPC, info: IRRPCCall & { start_time?: number }, result: unknown, exception: unknown): Promise<unknown> {
    const time = (Date.now() - info.start_time) / 1000; // show as seconds
    DebugService.instance.logAPICall(info, result, exception, time);
    if (ApiService.instance.#debug) console.log('APIcall:', info, result, exception, time);
    if (exception instanceof APIError) {
      if (exception.status === 'INVALID_SESSION')
        (AppService.instance as InternalAppService).invalidSession().catch(console.error);
      if (exception.status === 'MAINTENANCE_MODE')
        (AppService.instance as InternalAppService).maintenance().catch(console.error);
    }
    if (exception === undefined)
      return result;
    else
      throw exception;
  }

}
