/* eslint-disable @typescript-eslint/space-before-function-paren */
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { IRRPCCall } from "@vierkant-software/remoterpc";
import { DebugEntryType, DebugEntry } from "./debug.service.interfaces";
import { environment } from "src/environments/environment";

declare global {
    export interface Window {
        vks: {
            dbgHistory?(amount: number): void;
            clearDbgHistory?(): void;
        };
    }
}

const vks = {
    console: {} as Record<string, (...args: unknown[]) => void>
};

function applyDebugPatches1(printConsole = false, patch = true) {
    // console.log('#### patch 1');
    vks.console = {
        log:   window.console.log,
        warn:  window.console.warn,
        error: window.console.error
    };
    if (!patch) return;
    window.console.log = function(...args) {
        DebugService.logConsole('log', args);
        if (printConsole)
            return vks.console.log.apply(this, args);
    };
    window.console.warn = function(...args) {
        DebugService.logConsole('warn', args);
        if (printConsole)
            return vks.console.warn.apply(this, args);
    };
    window.console.error = function(...args) {
        DebugService.logConsole('error', args);
        if (printConsole)
            return vks.console.error.apply(this, args);
    };
};

function applyDebugPatches2() {
    // console.log('#### patch 2');
    const origThen = Promise.prototype.then;
    const origCatch = Promise.prototype.catch;
    Promise.prototype.then = function(t, c) {
        return origThen.apply(this, [t, ... c ? [(x: unknown) => {
            DebugService.instance.logCatch(x);
            return c(x);
        }] : []]);
    };
    Promise.prototype.catch = function(e) {
        return origCatch.apply(this, [(x: unknown) => {
            DebugService.instance.logCatch(x);
            return e(x);
        }]);
    };
}

const stripArgs4Call = [
    'AuthWorker.addUserCredential',
    'AuthWorker.auth',
    'AuthWorker.changePassword',
    'AuthWorker.updateUserCredential',
];

export class DebugService {
    static instance: DebugService;

    #events: DebugEntry[] = [];

    constructor() {
        // console.log('construct DebugService');
        applyDebugPatches1(!environment.production || true, false); // TODO disable console on prodmode
        DebugService.instance = this;
        if (!window.vks) window.vks = {};
        window.vks.dbgHistory = (amount) => this.printDebugHistory(amount);
        window.vks.clearDbgHistory = () => this.clearHistory();
    };

    __init(router: Router) {
        router.events.subscribe(event => this.logNavigation(event));
        applyDebugPatches2();
    }

    logNavigation(event: unknown) {
        // if (event instanceof RouteConfigLoadStart)
        //     console.log('RCL', event.route.outlet, event.route.path);
        if (
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
            this.#events.push({ type: DebugEntryType.navigation, event } as DebugEntry);
    }

    logAPICall(info: IRRPCCall, result: unknown, exception: unknown, time: number) {
        // we need to strip args for some calls
        if (stripArgs4Call.includes([info.worker, info.method].join('.'))) {
            this.#events.push({
                type: DebugEntryType.apiCall,
                info: { ...info, args: '<removed>', realArgs: '<removed>' },
                result,
                exception,
                time
            } as DebugEntry);
        } else {
            this.#events.push({ type: DebugEntryType.apiCall, info, result, exception, time } as DebugEntry);
        }
    }

    logClientError(error: unknown, report: unknown) {
        this.#events.push({ type: DebugEntryType.clientError, error, report });
    }

    static logConsole(severity: 'log' | 'warn' | 'error', args: unknown) {
        if (DebugService.instance)
            DebugService.instance.logConsole(severity, args);
        else
            vks.console.error('damn', severity, args);
    }

    logConsole(severity: 'log' | 'warn' | 'error', args: unknown) {
        this.#events.push({ type: DebugEntryType.console, severity, args } as DebugEntry);
    }

    static logUnauthorized(required: string) {
        if (!DebugService.instance) return;
        DebugService.instance.logUnauthorized(required);
    }

    logUnauthorized(required: string) {
        this.#events.push({ type: DebugEntryType.unauthorized, required });
    }

    /**
     * Log custom events to history
     * 
     * @param source a handle
     * @param data custom data
     */
    log(source: string, data?: unknown) {
        this.#events.push({ type: DebugEntryType.unknown, source, data} as DebugEntry);
    }

    logCatch(error: unknown) {
        this.#events.push({ type: DebugEntryType.catch, error } as DebugEntry);
    }

    clearHistory() {
        this.#events = [];
    }

    printDebugHistory(amount: number = 10) {
        this.#events.slice(-amount).forEach(entry => {
            switch(entry.type) {
                case DebugEntryType.navigation:
                    vks.console.log(entry.event.constructor.name, entry.event);
                    break;
                case DebugEntryType.apiCall:
                    const info = entry.info as unknown as IRRPCCall;
                    const call = [info.worker, info.method].join('.');
                    if (entry.exception) {
                        // eslint-disable-next-line max-len
                        vks.console.log('### ERROR ###\n' + ['type', 'msg', 'status', 'severity'].map(k => k + ': ' + entry.exception[k as keyof {}]).join('\n'));
                        vks.console.error('API ' + call, entry.exception.constructor.name, entry.info, entry.exception, entry.time);
                    } else {
                        vks.console.log('API ' + call, entry.info, entry.result, entry.time);
                    }
                    break;
                case DebugEntryType.clientError:
                    vks.console.log('### ERROR ###\n' + ['type', 'msg', 'status', 'severity'].map(k => k + ': ' + entry.error[k as keyof {}]).join('\n'));
                    vks.console.error('Client', entry.error, entry.report);
                    break;
                case DebugEntryType.catch:
                    vks.console.log('### ERROR ###\n' + ['type', 'msg', 'status', 'severity'].map(k => k + ': ' + entry.error[k as keyof {}]).join('\n'));
                    vks.console.error('catch', entry.error);
                    break;
                case DebugEntryType.console:
                    vks.console[entry.severity](...entry.args);
                    break;
                case DebugEntryType.unauthorized:
                    vks.console.warn('unauthorized - missing:', entry.required);
                    break;
                default:
                    vks.console.log('unknown', entry);
            }
        });
    }
}
